import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Editable } from '../components';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useAuth0 } from "@auth0/auth0-react";

const COL_SIZE=2;

const ProfileForm = () => {
    const { user, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
      <div>
        <Form>
            <Form.Group className="mb-3" as={Row} controlid='name'>
                <Form.Label column sm={COL_SIZE}>Name</Form.Label>
                <Col xs="auto">
                    <Editable text={user.name} placeholder="Full Name">
                        <Form.Control type="text" placeholder="Full Name" value={user.name} />
                    </Editable>
                </Col>
            </Form.Group>
            <Form.Group className="mb-3"  as={Row} controlid='email'>
                <Form.Label column sm={COL_SIZE}>Email</Form.Label>
                <Col xs="auto">
                    <Editable text={user.email} placeholder="Email">
                        <Form.Control type="text" placeholder="Email" value={user.email}/>
                    </Editable>
                </Col>
            </Form.Group>
            <br/>
            <Button variant="outline-secondary" type="submit">
                update
          </Button>
        </Form>
      </div>
  );

}

export default ProfileForm;
