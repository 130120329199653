import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { OrgTabSet, SubscriptionTabSet, UserManager } from '../components';
import { OrgClient, ListOrgCommand } from '../api';

const Organization = () => {
    const [org, setOrg] = useState({
        name: null,
        id: null,
        Enabled: true,
    });
    // const token = useAccessToken("etsm", "read:org")


    useEffect(() => {
        if (org.id === null) {
            const client = new OrgClient({token: "token"});
            client.execute(new ListOrgCommand(), setOrg);
        }

    }, [org.id]);

    return(

        <Container>
        <br/>
         <Tabs variant="tabs" transition={true} color="gray" defaultActiveKey="org" id="orgRootTabs" className="mb-3">
              <Tab eventKey="org" title="Profile">
                <br/>
                 <OrgTabSet />
              </Tab>
               <Tab eventKey="users" title="User Manager">
                <UserManager />
              </Tab>
              <Tab eventKey="subscription" title="Subscription">
                <SubscriptionTabSet />
              </Tab>
        </Tabs>
    </Container>

    );
}

export default Organization;
