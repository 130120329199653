import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import {NavHeader as Navbar} from './components';
import { Outlet } from "react-router-dom";



function App() {

  return (
    <div className="App">

      <Navbar />
      <div align="left">
        <Outlet />
      </div>

    </div>
  );
}

export default App;
