import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Editable } from '../components';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const COL_SIZE = 2;

function AddressForm (props) {

  return(
      <Form>
          <Form.Group className="mb-3" as={Row} controlid={`${props.prefix.toLowerCase()}_address_1`}>
            <Form.Label column sm={COL_SIZE}>Street Address 1</Form.Label>
            <Col>
              <Editable text="" placeholder="">
                <Form.Control type="text" placeholder=""></Form.Control>
                </Editable>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3" as={Row} controlid={`${props.prefix.toLowerCase()}_address_2`}>
            <Form.Label column sm={COL_SIZE}>Street Address 2</Form.Label>
            <Col>
               <Editable text="" placeholder="">
                <Form.Control type="text" placeholder=""></Form.Control>
              </Editable>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3" as={Row} controlid={`${props.prefix.toLowerCase()}_city`}>
            <Form.Label column sm={COL_SIZE}>City</Form.Label>
            <Col>
              <Editable text="" placeholder="">
                <Form.Control type="text" placeholder=""></Form.Control>
              </Editable>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3" as={Row} controlid={`${props.prefix.toLowerCase()}_state`}>
            <Form.Label column sm={COL_SIZE}>State/Province</Form.Label>
            <Col>
              <Editable text="" placeholder="">
                <Form.Control type="text" placeholder=""></Form.Control>
              </Editable>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3" as={Row} controlid={`${props.prefix}_country`}>
            <Form.Label column sm={COL_SIZE}>Country</Form.Label>
            <Col>
              <Editable text="" placeholder="">
                <Form.Control type="text" placeholder=""></Form.Control>
              </Editable>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3" as={Row} controlid={`${props.prefix.toLowerCase()}_postalcode`}>
            <Form.Label column sm={COL_SIZE}>Postal Code</Form.Label>
            <Col>
              <Editable text="" placeholder="">
                <Form.Control type="text" placeholder=""></Form.Control>
              </Editable>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3" as={Row}>
            <Form.Label column sm={COL_SIZE} controlid={`${props.prefix.toLowerCase()}_phone_number`}>Phone Number</Form.Label>
            <Col>
              <Editable text="" placeholder="">
                <Form.Control type="text" placeholder=""></Form.Control>
              </Editable>
            </Col>
          </Form.Group>
          <br/>
          <Button variant="outline-secondary" type="submit">
            Submit
          </Button>
      </Form>
  );
}

export default AddressForm;
