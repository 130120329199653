import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

const COL_SIZE=2;


const SubscriptionForm = () => {
    return(
        <>
        <Form>
            <Form.Group className="mb-3" controlid='subscriptionPlan'>
                <Form.Label column sm={COL_SIZE}>Plan</Form.Label>
                <Form.Label column>Easy Tree Lite</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlid='subscriptionSeats'>
                <Form.Label column sm={COL_SIZE}>Active Seats</Form.Label>
                <Form.Label column>2</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlid='accountStanding'>
                <Form.Label column sm={COL_SIZE}>Status</Form.Label>
                <Form.Label column>
                    <Badge pill bg="success" className="xs">
                        PAID
                    </Badge>
                </Form.Label>
            </Form.Group>
             <Form.Group className="mb-3" controlid='nextBill'>
                <Form.Label column sm={COL_SIZE}>Next Bill</Form.Label>
                <Form.Label column>08/01/2022</Form.Label>
            </Form.Group>
        </Form>
        </>

    );
}

export default SubscriptionForm;
