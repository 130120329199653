import Dropdown from 'react-bootstrap/Dropdown';
import { GearFill } from 'react-bootstrap-icons';

const ActionsDropdown = (props) => {
    return(
        <Dropdown >
            <Dropdown.Toggle variant="outline-secondary">
            <GearFill />&nbsp;Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {props.actions && props.actions.map((p) => {
                    return(
                        <Dropdown.Item key={p.title} href={p.link}>{p.title}</Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ActionsDropdown;
