import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from "@auth0/auth0-react";
import { Admin, Organization, Profile, Subscription } from './containers';
import { Login, Logout, ProtectedRoute } from './components';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

import { store } from './store';
import { Provider } from 'react-redux';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Auth0Provider
    domain="etsm.auth0.com"
    clientId="MT2lYEKKaIhgcM36Ldg3aZQ9Sw0ASRTa"
    redirectUri={window.location.origin}
    audience="etsm"
    >
    <BrowserRouter>
       <Provider store={store}>
       <App />
       <Routes>
          <Route path="/" element={<ProtectedRoute component={Profile} />} />
          <Route path="/admin" element={<ProtectedRoute component={Admin} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<ProtectedRoute component={Logout} />} />
          <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
          <Route path="/myorg" element={<ProtectedRoute component={Organization} />} />
          <Route path="/subscription" element={<ProtectedRoute component={Subscription} />} />
      </Routes>
      </Provider>
    </BrowserRouter>
  </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
