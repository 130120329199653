import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import { Plus } from 'react-bootstrap-icons';

const OrgSelector = () => {
    return (
        <>
        <br/>
        <Table>
        <tr>
            <td align="left">
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                    Choose Organization
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Org 1</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Org 2</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Org 3</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </td>
             <td align="right">
                <Button variant="outline-success"><Plus/>&nbsp;Add Org</Button>
            </td>
        </tr>
        </Table>
        </>
    );
}

export default OrgSelector;
