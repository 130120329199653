import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { BookFill, LifePreserver, PersonFill, PersonCircle } from 'react-bootstrap-icons';

const NavHeader = () => {
    const { user, isAuthenticated, logout } = useAuth0();
    return(
       <Navbar bg='dark' variant='dark'>
        <Container fluid>
          <Navbar.Brand sticky="top">
            <Nav.Link as={Link} to="/profile">
              <img
                src="/logo.png"
                alt=""
                className="d-inline-block align-middle"
              />
              </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle />

          {isAuthenticated &&
            <>
              <Nav className="me-auto" >
                <Nav.Link as={Link} to="/myorg">My Org</Nav.Link>
                <Nav.Link as={Link} to="/admin">Admin</Nav.Link>
              </Nav>

              <NavDropdown eventKey={4} title={<span>{user.name}<PersonCircle /></span>} align="end" id="nav-dropdown">
                <NavDropdown.Item as={Link} to="/profile" eventKey="4.1"><PersonFill />&nbsp; Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="4.2"><BookFill />&nbsp;Docs</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3"><LifePreserver />&nbsp;Support</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="logout" onClick={() => logout({ returnTo: window.location.origin })}>Logout</NavDropdown.Item>
              </NavDropdown>

            </>

          }

        </Container>

      </Navbar>

    )
}

export default NavHeader;
