import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Editable } from '../components';

const COL_SIZE=2;

const OrgForm = () => {
    return(
        <Form>
            <Form.Group as={Row} className="mb-3" controlId="formOrgName">
                <Form.Label column sm={COL_SIZE}>Name</Form.Label>
                <Col>
                    <Editable text="My Org" placeholder="Organization Name">
                        <Form.Control type="text" placeholder="Organization Name" value="My Org" />
                    </Editable>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formOrgID">
                <Form.Label column sm={COL_SIZE}>Organization ID</Form.Label>
                <Form.Label column>66143e6e-aebb-4249-a717-4a1a34c25d24</Form.Label>
            </Form.Group>
            <br/>
            <Button variant="outline-secondary" type="submit">update</Button>
        </Form>
    );
}

export default OrgForm;
