import Container from 'react-bootstrap/Container';

function Subscription() {
    return(
        <Container>
            <br/>
            <h1>My Subscription:</h1>
        </Container>
    );
}

export default Subscription;
