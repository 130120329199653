import { APICommand } from './APICommand.ts';
import APIResponse from './APIResponse.ts';

const DEFAULT_CONTENT_TYPE: string = "application/json";
const DEFAULT_ACCEPT_TYPE: string = "application/json";
const DEFAULT_USER_AGENT: string = "EasyTree-js-client/0.0.1";

///
export enum CORSMode {
    NoCors = "no-cors",
    Cors = "cors",
    SameOrigin = "same-origin",
}

///
export default class APIClient {
    #accept_type: String = DEFAULT_ACCEPT_TYPE;
    #content_type: String = DEFAULT_CONTENT_TYPE;
    #cors_mode: CORSMode = CORSMode.Cors;
    #credentials: APICredential;
    #user_agent: String = DEFAULT_USER_AGENT;

    ///
    constructor(credentials: APICredential) {
        this.#credentials = credentials;
    }

    ///
    public with_accept_type(accept_type: String) {
        this.#accept_type = accept_type;
    }

    ///
    public with_content_type(content_type: String) {
        this.#content_type = content_type;
    }

    ///
    public with_cors(cors_mode: CORSMode) {
        this.#cors_mode = cors_mode;
    }

    ///
    public user_agent(): String {
        return this.#user_agent
    }

    ///
    public async execute(command: APICommand, callback: Function): APIResponse<Type> {
        fetch(command.uri(), {
            headers: {
                'Accept': this.#accept_type,
                'Authorization': `Bearer ${this.#credentials.token}`,
                'Content-Type': this.#content_type,
                'User-Agent': this.#user_agent,
            },
            method: command.method,
            mode: this.#cors_mode,
            body: command.body,
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.text().then(text => {throw new Error(text)})
            }
        })
        .then((json) => {
            console.group("Organization:", json.name);
            console.info({payload: json} as APIResponse<Type>);
            console.groupEnd();
            callback(json);
            return  {payload: json} as APIResponse<Type>;
        })
        .catch((e) => {
            console.group()
            console.error(e.message);
            console.error(e);
            console.groupEnd();
            return {payload: {"kind": "error", message: e.message}} as APIResponse<Error>;
        });

    }
}
