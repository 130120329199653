import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { CreditCardForm, SubscriptionForm } from '../components';
import { CreditCardFill, FileRuledFill } from 'react-bootstrap-icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_O1VBGfymOOuMaiL9nEH3bIs4');

const SubscriptionTabSet = () => {
    return(
        <Tab.Container defaultActiveKey="subscriptionPlan" id="subscriptionForm" className="mb-3">
            <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                    <Nav.Link eventKey="subscriptionPlan"><FileRuledFill />&nbsp;Plan Summary</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="subscriptionMethod"><CreditCardFill />&nbsp;Payment Method</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
                <Tab.Content>
                     <Tab.Pane eventKey="subscriptionPlan" title="Profile">
                       <SubscriptionForm />
                    </Tab.Pane>
                    <Tab.Pane eventKey="subscriptionMethod" title="Payment Method">
                        <div>
                            <p>If you need to change your card on-file or update an expired card, please update here.</p>
                        </div>
                        <Elements stripe={stripePromise}>
                            <CreditCardForm />
                        </Elements>
                    </Tab.Pane>
                </Tab.Content>
            </Col>
        </Row>
        </Tab.Container>
    );
}

export default SubscriptionTabSet;
