import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import { UserTabSet } from '../components';

function Profile() {
    return(
    <Container>
        <br/>
         <Tabs defaultActiveKey="userProfile" id="userRootTab" className="mb-3">
              <Tab eventKey="userProfile" title="Profile">
                <br/>
                <UserTabSet />
              </Tab>
        </Tabs>
    </Container>
    );
}

export default Profile;
