import Table from 'react-bootstrap/Table';
import { ActionsDropdown } from '../components';

const Actions = [{title: "Credentials", link: ""}
                ,{title: "Edit", link: ""}
                ,{title: "Disable", link: ""}
                ];

const VOIPProfile = () => {
    return(
    <Table flat striped hover>
      <thead>
        <tr>
          <th>Device Name</th>
          <th>Device Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Acrobits Softphone</td>
          <td>Softphone</td>
          <td align="center"><ActionsDropdown actions={Actions} /></td>
        </tr>
        <tr>

          <td>Comm.land</td>
          <td>Desktop Client</td>
          <td align="center"><ActionsDropdown actions={Actions} /></td>
        </tr>
        <tr>
          <td>Deskphone</td>
          <td>Yealink 722</td>
          <td align="center"><ActionsDropdown actions={Actions} /></td>
        </tr>
      </tbody>
    </Table>
    );
}

export default VOIPProfile;
