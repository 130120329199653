import React from 'react';
import { Container } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { OrgSelector } from '../admin';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { UserManager, SubscriptionTabSet, OrgTabSet } from '../components';

const Admin = () => {
    const {user, isLoading} = useAuth0();

    if(isLoading) {
        return "<div>.....</div>";
    }

    console.log(user);

    return(
        <Container>
            <br/>
            <OrgSelector />
            <br/>
             <Tabs variant="tabs" transition={true} color="gray" defaultActiveKey="org" id="orgRootTabs" className="mb-3">
                  <Tab eventKey="org" title="Profile">
                    <br/>
                     <OrgTabSet />
                  </Tab>
                   <Tab eventKey="users" title="User Manager">
                    <UserManager />
                  </Tab>
                  <Tab eventKey="subscription" title="Subscription">
                    <SubscriptionTabSet />
                  </Tab>
            </Tabs>
    </Container>
    );
}

export default Admin;

