import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const CreditCardForm = () => {

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();  useEffect(() => {

    window.fetch("/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    }).then((res) => {
      return res.json();
    }).then((data) => {
      setClientSecret(data.clientSecret);
    });
  }, []);  const handleChange = async (event) => {

    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };
return (
    <>
    <br/>
    <Form sm={3} id="payment-form" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" >
          <CardElement
            id="card-element"
            className="w-50"
            options={{}}
            onChange={handleChange}
          />
        </Form.Group>
      <br/>
      <Button variant="outline-secondary" disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? <div className="spinner" id="spinner"></div> : "update"}
        </span>
      </Button>
      </Form>

      {error && (
        <div className="card-error" role="alert">{error}</div>
      )}      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>Payment succeeded!</p>

    </>
  );
}

export default CreditCardForm;
