
import APIClient  from './APIClient';
import APICommand from './APICommand';

///
export class Address {
    street1: string;
    street2?: string;
    city: string;
    state: string;
    country: string;
    phone_number: string;
}

///
export class Organization {
    org_id?: string;
    org_name: string;
    billing_profile: Address;
    shipping_profile?: Address;
    emergency_profile?: Address;

    constructor(name: String) {
        this.org_name = name;
    }

    ///
    public with_billing_profile(addr: Address) {
        this.addr = addr;
    }

    ///
    public with_shipping_profile(addr: Address) {
        this.addr = addr;
    }

    ///
    public with_emergency_profile(addr: Address) {
        this.addr = addr;
    }
}

export class CreateOrgCommand extends APICommand {
    body: Organization;

    constructor(org: Organization) {
        super();
        this.name="createOrgCommand";
        this.base_uri = "http://api.easytree.io:8000";
        this.resource="/org";
        this.method="POST";
        this.body = org;
    }

    // public uri(): string
    // {
    //     return `${this.base_uri}${this.resource}`;
    // }

}

// export class UpdateOrgCommand implements APICommand {
//     body: Organization;

//     constructor(org: Organization) {

//         this.name="updateOrgCommand";
//         this.base_uri = "http://api.easytree.io:8000";
//         this.resource="/org/";
//         this.method="PUT";
//         this.body = org;
//     }

//     public uri(self): string
//     {
//         return `${this.base_uri}${this.resource}`;
//     }
// }

export class ListOrgCommand implements APICommand {
    constructor(org: Organization) {

        this.name="listOrgCommand";
        this.base_uri = "http://api.easytree.io:8000";
        this.resource="/org";
        this.method="GET";
        this.body = org;
    }

    public uri(): string
    {
        return `${this.base_uri}${this.resource}`;
    }
}

///
export default class OrganizationClient extends APIClient {
    // constructor(credentials: APICredential) {
    //     // super(credentials);
    // }

}
