import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { AddressForm, ProfileForm, VOIPProfile } from '../components';
import { Box2Fill, HeartPulseFill, PersonBadgeFill, TelephoneFill } from 'react-bootstrap-icons';

const UserTabSet = () => {
    return(
        <Tab.Container defaultActiveKey="userProfile" id="userProfileTabs" className="mb-3">
            <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                    <Nav.Link eventKey="userProfile"><PersonBadgeFill />&nbsp;Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="userVOIP"><TelephoneFill />&nbsp;VOIP Services</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="userShipping"><Box2Fill />&nbsp;Shipping Address</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="userEmergency"><HeartPulseFill />&nbsp;Emergency Services</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
                <Tab.Content>
                     <Tab.Pane eventKey="userProfile" title="Profile">
                       <ProfileForm />
                    </Tab.Pane>
                     <Tab.Pane eventKey="userVOIP" title="VOIP Services">
                       <VOIPProfile />
                    </Tab.Pane>
                    <Tab.Pane eventKey="userShipping" title="Shipping Address">
                        <AddressForm prefix="shipping" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="userEmergency" title="Emergency">
                        <AddressForm prefix="emergency" />
                    </Tab.Pane>
                </Tab.Content>
            </Col>
        </Row>
        </Tab.Container>
    );
}

export default UserTabSet;
