import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { ActionsDropdown } from '../components';
import { Plus } from 'react-bootstrap-icons';

const Actions = [{title: "Edit", link: "", icon: ""},
                 {title: "Disable", link: "", icon: ""},
                 {title: "Delete", link: "", icon: ""}];

const UserManager = () => {
    return(
    <>
    <br/>
    <div align="right">
        <Button variant="outline-success"><Plus />&nbsp;Add User</Button>
    </div>
    <br/>
    <Table flat striped hover>
      <thead>
        <tr>
          <th>User</th>
          <th>Email</th>
          <th>Role</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>John White</td>
          <td>john@mk1s.com</td>
          <td>Super Admin</td>
          <td align="center"><ActionsDropdown actions={Actions}/></td>
        </tr>
        <tr>
          <td>Ron Shoe</td>
          <td>rshoe@sipoasis.com</td>
          <td>Super Admin</td>
          <td align="center"><ActionsDropdown actions={Actions}/></td>
        </tr>
        <tr>
          <td>Mitch Mitchell</td>
          <td>mitch@sipoasis.com</td>
          <td>Org Admin</td>
          <td align="center"><ActionsDropdown actions={Actions} /></td>
        </tr>
        <tr>
          <td>Buddy Budderson</td>
          <td>buddy@sipoasis.com</td>
          <td>Org User</td>
          <td align="center"><ActionsDropdown actions={Actions} /></td>
        </tr>
      </tbody>
    </Table>
    </>
    );
}

export default UserManager;
